import * as React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {getOperationGroup, getOrder, getServiceNameFromLocation} from '../../util/helperUtil';
import {SpaceBetween} from "@amzn/awsui-components-react";
import DescriptionBox from "../common/DescriptionBox";
import {useTranslator} from "../../localization/Translator";
import GroupSelectPage from '../common/GroupSelectPage';

const Operation = () => {
  const t = useTranslator();
  const location = useLocation();
  const navigate = useNavigate();
  const operationsString = localStorage.getItem("operations") || JSON.stringify([""]);
  const operations: string[] = JSON.parse(operationsString);
  const serviceName = getServiceNameFromLocation(location);
  const navigateToSite = (operation: string) => {
    navigate(operation);
  }

  const getButtonName = (operation: string) => {
    return t.translate(operation);
  }
  
  const groupOperationsMap: any = {};
  operations.forEach(operation => {
    const operationGroup = getOperationGroup(operation);
    if (!operationGroup) {
      return;
    }
    if (!groupOperationsMap[operationGroup.nameKey]) {
      groupOperationsMap[operationGroup.nameKey] = [];
    }
    groupOperationsMap[operationGroup.nameKey].push(operation);
  });

  const groups = [];
  for (const operationGroupNameKey in groupOperationsMap) {
    groups.push({
      groupSelectName: t.translate(operationGroupNameKey),
      selectPages: {
        data: groupOperationsMap[operationGroupNameKey],
        buttonOnClick: navigateToSite,
        buttonNameFn: getButtonName
      },
      order: getOrder(operationGroupNameKey)
    });
  }
  groups.sort((group1, group2) => group1.order - group2.order)
  return (
    <SpaceBetween size={"xs"} alignItems="center">
      <DescriptionBox operations={operations} serviceName={serviceName}/>
      <GroupSelectPage
        headerTitle={t.translate("operation_component_header", {
          // To update the service name with localization if needed in the future.
          serviceName: serviceName.toUpperCase()
        })}
        group={groups}
      />
    </SpaceBetween>
  );
}
export default Operation;