import {Cards, SpaceBetween} from '@amzn/awsui-components-react';
import ConfirmationMessage from './ConfirmationMessage';
import Button from '@amzn/awsui-components-react/polaris/button';
import * as React from 'react';
import {EventHandler} from 'react';
import ContentHeader from './ContentHeader';
import ErrorNotification from './ErrorNotification';

export enum ResponsePageType {
  SUCCESS = 'success', ERROR = 'error', INFORMATION = ''
}

export interface ResponsePageProps {
  message?: any,
  orderedList?: string[],
  buttonName?: string,
  buttonOnClick?: EventHandler<any>;
  buttonName2?: string,
  buttonOnClick2?: EventHandler<any>;
  headerTitle: string;
  pageType?: ResponsePageType,
  linkText?: string,
  link?: string
  errorCode?: string,
  data?: string,
}

const ResponsePage = (props: ResponsePageProps) => {
  const cards = [{cards: 1}];
  const items = [{
    type: "1A",
    size: "Small"
  }];
  return (
    <SpaceBetween size={"xxl"} alignItems={'center'}>
      <ContentHeader title={props.headerTitle}/>
      <Cards
        cardDefinition={{
          header: item => (
            <SpaceBetween size={"xxl"} alignItems={'center'} className={props.pageType}>
              {props.message && <ConfirmationMessage
                message={props.message}
                orderedList={props.orderedList}
                linkText={props.linkText}
                link={props.link}/>}
              {!props.message && props.errorCode && <ErrorNotification errorCode={props.errorCode} data={props.data}/>}
              <SpaceBetween direction="horizontal" size="xs" alignItems={'end'}>
                {props.buttonName && <Button
                    formAction="none"
                    variant="primary"
                    onClick={props.buttonOnClick}>
                  {props.buttonName}
                </Button>}
                {props.buttonName2 && <Button
                    formAction="none"
                    variant="primary"
                    onClick={props.buttonOnClick2}>
                  {props.buttonName2}
                </Button>}
              </SpaceBetween>
            </SpaceBetween>
          )
        }}
        cardsPerRow={cards}
        items={items}
      />
    </SpaceBetween>
  )
}

export default ResponsePage;