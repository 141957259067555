import * as React from 'react';
import {Box, Container, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {
  GetBadgeDetailsResponse,
  REGISTERED_BADGE_FOUND,
  RR_ERROR_UNASSIGNED_BADGE
} from "../../../module/AuthAdminModule";
import DisplayAssociateDetails from "../../common/DisplayAssociateDetails";
import Button from "@amzn/awsui-components-react/polaris/button";
import {currentApiDomain, getTimeZoneAndFormat} from "../../../util/helperUtil";
import {useTranslator} from '../../../localization/Translator';
import ReRegisterAssociate from '../re-register/ReRegisterAssociate';
import ErrorNotification from '../../common/ErrorNotification';

interface ShowStatusProps {
    data: GetBadgeDetailsResponse
}

const ShowStatus = (props: ShowStatusProps) => {
    const t = useTranslator();
  const [showReRegisterAssociate, setShowReRegisterAssociate] = React.useState(false);
    const hasUserDetails = props.data.responseMessage === REGISTERED_BADGE_FOUND;

    const translateBadgeData = (header: string, value: any) => {
        if (value === null) {
            // return t.translate(header).append("N/A");
            return t.translate(header, {data: "N/A"});
        }

        switch (header) {
            case "badge_details_loaner_badge":
                value = value ? "Yes" : "No";
                break;
            case "badge_details_created_at":
                value = new Date(value).toLocaleString() + getTimeZoneAndFormat(value);
                break;
            case "badge_details_registered_at":
                value = new Date(value).toLocaleString() + getTimeZoneAndFormat(value);
                break;
        }
      // return t.translate(header) + value;
        return t.translate(header, {data: value});
    }

  if (showReRegisterAssociate && props.data.userId) {
    return <ReRegisterAssociate userId={props.data.userId}/>
  }

    return (
        <SpaceBetween direction="vertical" size="m">
          {showReRegisterAssociate && !props.data.userId &&
              <ErrorNotification errorCode={RR_ERROR_UNASSIGNED_BADGE} variables={{cardUid: props.data.cardId,
                data: RR_ERROR_UNASSIGNED_BADGE}}/>
          }
            <div style={{display: "flex", gap: 50}}>
                    <div style={{flex: 1}}>
                        {
                            !hasUserDetails &&
                            <Container fitHeight header={<h1 style={{textAlign: "center"}}>{t.translate("associate_details_header")}</h1>}>
                                {t.translate("unregistered_badge_found_message")}
                            </Container>
                        }
                        {
                            hasUserDetails && <DisplayAssociateDetails data={props.data} />
                        }
                    </div>
                    <div style={{flex: 1}}>
                        <Container fitHeight header={<h1 style={{textAlign: "center"}}>{t.translate("badge_details_header")}</h1>}>
                            <SpaceBetween direction="vertical" size="s">
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_id", props.data.cardId)}</Box>
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_status", props.data.badgeStatus)}</Box>
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_loaner_badge", props.data.isLoanerBadge)}</Box>
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_created_at", props.data.badgeCreatedAt)}</Box>
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_registered_at", props.data.badgeRegisteredAt)}</Box>
                                <Box fontSize="body-m" fontWeight="bold">{translateBadgeData("badge_details_managed_by", props.data.badgeManagedBy)}</Box>
                            </SpaceBetween>
                        </Container>
                    </div>
            </div>
          <SpaceBetween direction="horizontal" size="xs" alignItems={'end'}>
            <Button variant="primary" onClick={() =>  window.location.replace(currentApiDomain + "/Authorize")}>
                {t.translate("return_to_home_page_button_name")}
            </Button>
            <Button variant="primary" onClick={() => setShowReRegisterAssociate(true)} disabled={!props.data.userId}>
                {t.translate("re_register_button_label")}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
    );
}

export default ShowStatus;