import * as React from "react";
import { FormEvent } from "react";
import { Trans } from "react-i18next";
import {
  Link,
  Navigate,
  NavigateFunction,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Form,
  FormField,
  Input,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import {
  currentApiDomain,
  getServiceNameFromLocation,
  isAllowedAsInput,
  isEmptyString, REQUEST_INIT,
} from "../../../util/helperUtil";
import ResponsePage, { ResponsePageType } from "../../common/ResponsePage";
import ContentHeader from "../../common/ContentHeader";
import FormActions from "../../common/FormActions";
import {
  CREATE_NEW_TEAM_URL,
  CreateSiteAdminResponse,
  Exception,
  HOME_PAGE_URL,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
  SessionDetails,
} from "../../../module/AuthAdminModule";
import ConfirmationMessage from "../../common/ConfirmationMessage";
import Notification, { NotificationType } from "../../common/Notification";
import ErrorNotification from "../../common/ErrorNotification";
import {useTranslator} from "../../../localization/Translator";

const CREATE_SITE_ADMIN_URL: string = `${currentApiDomain}/MapAdminGroupAndLocation`;
const CREATE_SITE_ADMIN_PAGE_URL: string =
  "/services/name_maask/operations/createLocationAdmin";
const EMPTY_STRING: string = "";
const EMPTY_ARRAY: string[] = [];

const TeamIdForm = () => {
  const t = useTranslator();
  const adminPermission = [
    t.translate("user_operation_permission_resetPin"),
    t.translate("user_operation_permission_viewReport"),
  ];
  const [errorCode, setErrorCode] = React.useState(EMPTY_STRING);
  const { state } = useLocation();
  const siteId = state.siteId;
  const groupId = state.groupId;
  const GroupIdExists = !isEmptyString(groupId);
  const navigate: NavigateFunction = useNavigate();
  const [teamId, setTeamId] = React.useState(
    GroupIdExists ? groupId : EMPTY_STRING
  );
  const [errorText, setErrorText] = React.useState(EMPTY_STRING);
  const [isLoading, setIsLoading] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(EMPTY_STRING);
  const [permissions, setPermissions] = React.useState(adminPermission);
  const [responsePageType, setResponsePageType] = React.useState(
    ResponsePageType.INFORMATION
  );
  const [responsePageHeaderTitle, setResponsePageHeaderTitle] =
    React.useState(EMPTY_STRING);
  const [isInputInvalid, setIsInputInvalid] = React.useState(true);

  const GROUP_ID_MAX_LENGTH = 40;
  const GROUP_ID_MIN_LENGTH = 10;

  const onChangeFn = (value: string) => {
    setErrorText(EMPTY_STRING);
    setIsInputInvalid(!validateGroupId(value));
    setTeamId(value);
  };
  const responsePageOnClick = () => {
    setResponseMessage(EMPTY_STRING);
    setTeamId(EMPTY_STRING);
    navigate(HOME_PAGE_URL);
  };
  const validateGroupId = (groupId: string) => {
    if (isEmptyString(groupId)) {
      setErrorText(t.translate("error_message_required_field"));
      return false;
    }
    if (
      groupId.length > GROUP_ID_MAX_LENGTH ||
      groupId.length < GROUP_ID_MIN_LENGTH
    ) {
      setErrorText(t.translate("teamId_length_error_message"));
      return false;
    }
    if (!isAllowedAsInput(groupId)) {
      setErrorText(t.translate("input_form_invalid_character_error_message"));
      return false;
    }
    return true;
  };
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);
  const sessionDetails: SessionDetails = useOutletContext();
  if (!sessionDetails?.csrfToken) {
    console.error("Could not find CSRF token, redirecting to home page.");
    return <Navigate to={HOME_PAGE_URL} />;
  }
  const setSuccessResponsePage = () => {
    setResponsePageType(ResponsePageType.SUCCESS);
    setResponseMessage(
        t.translate("response_message_create_admin_success", {
          teamId: teamId,
          siteId: siteId.toUpperCase()
        })
    );
    setPermissions(EMPTY_ARRAY);
    setResponsePageHeaderTitle(
      t.translate("response_message_create_admin_success_header_title")
    );
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!validateGroupId(teamId)) return;
    setIsLoading(true);
    fetch(CREATE_SITE_ADMIN_URL, {
      method: "POST",
      ...REQUEST_INIT,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "anti-csrftoken-a2z": sessionDetails.csrfToken,
      },
      body: JSON.stringify({
        locationId: siteId,
        groupId: teamId,
        serviceName: serviceName,
      }),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        if (res.hasOwnProperty("responseCode")) {
          res = res as CreateSiteAdminResponse;
          if (res.responseCode === 200) {
            setSuccessResponsePage();
            return;
          }
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          return;
        }
        // Exception from backend
        if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  const groupIdDescription = (
    <Trans>
      {t.translate("group_id_form_field_description_1")}
      <Link to={CREATE_NEW_TEAM_URL}>{t.translate("form_field_link_text")}</Link>
      {t.translate("group_id_form_field_description_2")}
    </Trans>
  );

  const formPage = (
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      {!errorCode && GroupIdExists && (
        <Notification
          notificationType={NotificationType.INFO}
          message={t.translate("alert_mapping_already_exists_message", {
            siteId: siteId.toUpperCase(),
            groupId: groupId,
          })}
          header={t.translate("admin_create_mapping_exists_header")}
        />
      )}
      {errorCode && <ErrorNotification errorCode={errorCode} />}
      <ContentHeader
        title={t.translate("team_component_header") + siteId.toUpperCase()}
      />
      <form style={{}} onSubmit={(e) => e.preventDefault()}>
        <Form
          className={"width-flex"}
          actions={
            <FormActions
              cancelButtonName={t.translate("cancel_button_name")}
              submitButtonName={t.translate("submit_button_name")}
              cancelOnClick={() => navigate(CREATE_SITE_ADMIN_PAGE_URL)}
              submitOnClick={handleSubmit}
              isLoading={isLoading}
              isInputInvalid={isInputInvalid}
            />
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField
              stretch
              label={t.translate("team_id_form_field_label")}
              errorText={errorText}
              description={groupIdDescription}
            >
              <Input
                value={teamId}
                onChange={(event) => onChangeFn(event.detail.value)}
              />
            </FormField>
            <ConfirmationMessage
                message={t.translate("admin_site_add_description_message", {
                  siteId: siteId.toUpperCase(),
                })}
            />
          </SpaceBetween>
        </Form>
      </form>
    </SpaceBetween>
  );

  const responsePage = (
    <ResponsePage
      message={responseMessage}
      buttonName={t.translate("back_button_name")}
      orderedList={permissions}
      buttonOnClick={responsePageOnClick}
      headerTitle={responsePageHeaderTitle}
      pageType={responsePageType}
    />
  );

  return isEmptyString(responseMessage) ? formPage : responsePage;
};

export default TeamIdForm;
