import * as React from 'react';
import {FormEvent, useState} from 'react';
import {NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {
  currentApiDomain,
  EMPTY_STRING,
  getServiceNameFromLocation,
  isAllowedAsInput,
  isEmptyString,
  REQUEST_INIT
} from "../../../util/helperUtil";
import {
  Exception,
  GetAssociateDetailsResponse,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS
} from "../../../module/AuthAdminModule";
import {Form, FormField, Input, SpaceBetween} from '@amzn/awsui-components-react';
import ContentHeader from '../../common/ContentHeader';
import FormActions from '../../common/FormActions';
import {useTranslator} from '../../../localization/Translator';
import {Trans} from 'react-i18next';
import ErrorNotification from '../../common/ErrorNotification';
import AssociateDetails from './AssociateDetails';

const SearchAssociate = () => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [inputValidationError, setInputValidationError] =
    React.useState(EMPTY_STRING);
  const [state, setState] = useState<GetAssociateDetailsResponse | null>(null);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [alias, setAlias] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInputInvalid, setIsInputInvalid] = React.useState(true);
  const [errorVariables, setErrorVariables] = React.useState<{[key: string]: {}} | null>({});

  const ASSOCIATE_DETAILS_URL: string = `${currentApiDomain}/AssociateDetails?`;
  const OPERATIONS_URL: string = "/services/name_maask/operations";
  const ALIAS_MAX_LENGTH = 40;

  const isValidAlias = (alias: string) => {
    if (alias.length > ALIAS_MAX_LENGTH) {
      setInputValidationError(t.translate("error_message_alias_length", {data: ALIAS_MAX_LENGTH}));
      return false;
    }
    if (isEmptyString(alias)) {
      setInputValidationError(t.translate("error_message_required_field"));
      return false;
    }
    if (!isAllowedAsInput(alias)) {
      setInputValidationError(t.translate("input_form_invalid_character_error_message"));
      return false;
    }
    return true;
  }
  const onChangeFn = (value: string) => {
    setInputValidationError(EMPTY_STRING);
    const isValid = isValidAlias(value);
    setIsInputInvalid(!isValid);
    if (value && !isValid) {
      return;
    }
    setAlias(value);
  }

  const handleSubmit = (e: FormEvent) => {
    setErrorCode(EMPTY_STRING);
    e.preventDefault();
    if (!isValidAlias(alias)) return;
    setIsLoading(true);
    fetch(ASSOCIATE_DETAILS_URL + new URLSearchParams({
      userId: alias, serviceName: serviceName
    }), {method: "GET", ...REQUEST_INIT})
      .then((res) => {
        setIsLoading(false);
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(currentApiDomain + "/Authorize");
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        // Success:200
        if (res.hasOwnProperty("responseCode")) {
          if (res.responseCode === 200 || res.responseCode === "200") {
            const responseMessage: GetAssociateDetailsResponse = res;
            if (!responseMessage.success) {
              // should not happen, backend should return with errorId instead of responseCode
              console.error("Unsuccessful response", res);
              setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
              setErrorVariables({alias: alias});
              return;
            }
            setState(responseMessage);
            return;
          }
          // should not happen, backend should return with errorId
          console.error("Response code other than 200", res);
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
          setErrorVariables({alias: alias});
          return;
        }
        // Exception from backend
        else if (res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          setErrorVariables({alias: alias});
          return;
        } else {
          // should not happen
          console.error("Unknown response", res);
          setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  const locationDescription = (
    <Trans>{t.translate("search_alias_description")}</Trans>
  );

  const associatePage = state && <AssociateDetails data={state}/>

  const formPage =
    <SpaceBetween size={"xxl"} alignItems={"center"}>
      {errorCode && <ErrorNotification errorCode={errorCode} variables={{...errorVariables}}/>}
      <ContentHeader title={t.translate("search_alias_header")}/>
      <form className={"margin-top-20"}>
        <Form
          className={"width-flex"}
          actions={
            <FormActions
              cancelButtonName={t.translate("cancel_button_name")}
              submitButtonName={t.translate("search_alias_button")}
              cancelOnClick={() => navigate(OPERATIONS_URL)}
              isLoading={isLoading}
              isInputInvalid={isInputInvalid}
              submitOnClick={handleSubmit}
            />
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField
              stretch
              errorText={inputValidationError}
              description={locationDescription}
              label={t.translate("search_alias_field")}>
              <Input value={alias}
                     onChange={(event) => onChangeFn(event.detail.value)}/>
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </SpaceBetween>

  return (
    <div style={{width: "60%", margin: "0 auto"}}>
      {associatePage || formPage}
    </div>
  )
}

export default SearchAssociate;