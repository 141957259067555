import * as React from 'react';
import ContentHeader from './ContentHeader';
import {ColumnLayout, SpaceBetween} from '@amzn/awsui-components-react';
import SelectPage, {SelectPageProps} from './SelectPage';

interface GroupPageProps {
  groupSelectName: string
  selectPages: SelectPageProps
}

interface GroupSelectPageProps {
  headerTitle: string
  group?: GroupPageProps[]
}

const GroupSelectPage = (props: GroupSelectPageProps) => {
  return (
    <SpaceBetween size={'xxl'}>
      <h1 className={"align-center"}>{props.headerTitle}</h1>
      <ColumnLayout columns={props.group?.length}>
        {props.group?.map(group => {
          return <div className={"section"}>
            <ContentHeader title={group.groupSelectName}/>
            <SelectPage data={group.selectPages.data} buttonOnClick={group.selectPages.buttonOnClick}
                        buttonNameFn={group.selectPages.buttonNameFn} buttonName={group.selectPages.buttonName}/>
          </div>
        })}
      </ColumnLayout>
    </SpaceBetween>
  );
}

export default GroupSelectPage;
