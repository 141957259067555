import * as React from 'react';
import {Box, Container, SpaceBetween} from "@amzn/awsui-components-react/polaris";
import {GetAssociateDetailsResponse, HOME_PAGE_URL, SessionDetails} from "../../../module/AuthAdminModule";
import DisplayAssociateDetails from "../../common/DisplayAssociateDetails";
import Button from "@amzn/awsui-components-react/polaris/button";
import {useTranslator} from '../../../localization/Translator';
import {Navigate, useOutletContext} from 'react-router-dom'
import ReRegisterAssociate from './ReRegisterAssociate';

interface ShowStatusProps {
  data: GetAssociateDetailsResponse
}

const AssociateDetails = (props: ShowStatusProps) => {
  const t = useTranslator();
  const [showReRegisterAssociate, setShowReRegisterAssociate] = React.useState(false);
  const sessionDetails: SessionDetails = useOutletContext();

  if (!sessionDetails?.csrfToken) {
    console.error("Could not find CSRF token, redirecting to home page.");
    return <Navigate to={HOME_PAGE_URL}/>;
  }

  const navigateToReRegister = () => {
    window.location.reload();
  }

  if (showReRegisterAssociate && props.data.userId) {
    return <ReRegisterAssociate userId={props.data.userId}/>
  }

  return (
    <SpaceBetween direction="vertical" size="m">
      <div style={{display: "flex", gap: 50}}>
        <div style={{flex: 1}}>
          {
            props.data.userId && <DisplayAssociateDetails data={props.data}/>
          }
        </div>
        <div style={{flex: 1}}>
          <Container fitHeight header={<h1 style={{textAlign: "center"}}>{t.translate("badge_details_header")}</h1>}>
            <SpaceBetween direction="vertical" size="s">
              <Box fontSize="body-m" fontWeight="bold">
                {props.data.employeeBadgeStatus
                  && t.translate("badge_details_status", {data: props.data.employeeBadgeStatus})}
                {!props.data.employeeBadgeStatus
                  && t.translate("user_not_assigned_badge_message")}
              </Box>
            </SpaceBetween>
          </Container>
        </div>
      </div>
      <SpaceBetween direction="horizontal" size="xs" alignItems={'end'}>
        <Button variant="primary" onClick={() => navigateToReRegister()}>
          {t.translate("back_button_label")}
        </Button>
        <Button variant="primary" onClick={() => setShowReRegisterAssociate(true)}>
          {t.translate("re_register_button_label")}
        </Button>
      </SpaceBetween>
    </SpaceBetween>
  );
}

export default AssociateDetails;